export const UNAUTHENTICATED_ROUTES = {
    LOGIN: "/login",
    RESET_APP_PASSWORD: "/reset-password/:resetCode/:Email",
    EVENT_SUCCESS: "/event-submitted",
    SIGN_UP: "/sign-up/:token",
    STRIPE_REDIRECTION: "/stripe-redirection"
};

export const AUTHENTICATED_ROUTES = {
    DASHBOARD: "/dashboard",
    INVITE_ADMIN: "/invite-admin",
    USER: "/user",
    VIEW_USER_DETAILS: "/user/:id",
    SERVICE_RATE: "/service-rates",
    OFFER_AND_PROMOTION: "/offer-and-promotions",
    PAYMENT: "/payments",
    MANAGE_RIDES: "/manage-rides",
    RIDE_SUMMARY: "/ride/:id",
    ACTIVATION_REQUESTS: "/activation-requests"
};

