import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { UNAUTHENTICATED_ROUTES } from "./constant";
import CustomRoute from "./CustomRoute";

const Login = React.lazy(() => import("../containers/AuthPages/Login"));
const ResetAppPassword = React.lazy(() => import("../containers/AuthPages/ResetAppPassword"));
const EventSubmitted = React.lazy(() => import("../containers/EventSubmitted"));
const SignUp = React.lazy(() => import("../containers/AuthPages/SignUp"));
const StripeRedirection = React.lazy(() => import("../containers/AuthPages/StripeRedirection/StripeRedirection"));

export default function UnAuthenticatedApp() {
    return (
        <Switch>
            <CustomRoute exact path={UNAUTHENTICATED_ROUTES.LOGIN} component={Login} title="Login" />
            <CustomRoute exact path={UNAUTHENTICATED_ROUTES.SIGN_UP} component={SignUp} title="Sign Up" />

            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.RESET_APP_PASSWORD}
                component={ResetAppPassword}
                title="Reset Password"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.EVENT_SUCCESS}
                component={EventSubmitted}
                title={"Event Success"}
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.STRIPE_REDIRECTION}
                component={StripeRedirection}
                title={"Stripe Redirection"}
            />

            <Route exact path="*" component={Login}>
                <Redirect to={UNAUTHENTICATED_ROUTES.LOGIN} />
            </Route>
        </Switch>
    );
}

